<template>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div :class="'card-header ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">{{ title }}</div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>{{ data.total_job }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Number of Jobs</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-briefcase"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.revenue.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Revenue</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.average_job }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Average Job</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.average_job_cost }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Average Job Cost</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.supplies_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Supplies Amount</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.gas_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Gas Amount</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.service_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Service Amount</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>${{ data.repair_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Repair Amount</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>{{ data.supplies_percentage }}%</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Supplies %</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-percentage"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>{{ data.gas_percentage }}%</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Gas %</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-percentage"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>{{ data.service_percentage }}%</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Service %</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-percentage"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>{{ data.repair_percentage }}%</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Repair %</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-percentage"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['data', 'title', 'filters'],
}
</script>