<template>
  <section class="content">
    <div class="container-fluid">
      <div class="card">
        <div :class="'card-header ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">{{ title }}</div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.grossPayModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.gross_pay.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Gross Pay</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.productionModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.production_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Production</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.wowAmountModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.wow_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Wow Amount</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.tipModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.tip_amount.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Tip</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.powerScoreModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.power_score.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Power Score</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.wowScoreModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.wow_score.toFixed(2) }}%</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Wow Score</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.hourWorkedModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.hours_worked }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Hours Worked</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-clock"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.grossPayModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.hourly_production_score.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Hourly Production Score </p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.productionModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.job }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Job</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-briefcase"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.wowAmountModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.wow }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Wow</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="far fa-surprise"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.powerScoreModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.personal_score.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Personal EH Score</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.powerScoreModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.client_score.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Client EH Score</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.powerScoreModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.communication_score.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Communication EH Score</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.hourWorkedModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.hourly_pay }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Hourly Pay</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')">
                <div class="inner">
                  <h3>{{ data.sales_commission }}%</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <p style="margin: 0">Commission</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-percentage"></i>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.hourWorkedModal.show(this.filters)">
                <div class="inner">
                  <h3>{{ data.avg_weekly_hr }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Avg. Weekly Hours</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-clock"></i>
                </div>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.productionModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.full_monty.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Full Monty</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-6">
              <!-- small card -->
              <div :class="'small-box mb-0 ' + (title === 'Summary' ? 'bg-blue' : 'bg-light')"
                   @click="$refs.productionModal.show(this.filters)">
                <div class="inner">
                  <h3>${{ data.hourly_monty.toFixed(2) }}</h3>

                  <div class="row">
                    <div class="col-md-12">
                      <p style="margin: 0">Hourly Monty</p>
                    </div>
                  </div>
                </div>
                <div class="icon">
                  <i class="fas fa-dollar-sign"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <gross-pay-modal ref="grossPayModal" />
  <production-modal ref="productionModal" />
  <wow-amount-modal ref="wowAmountModal" />
  <tip-modal ref="tipModal" />
  <power-score-modal ref="powerScoreModal" />
  <wow-score-modal ref="wowScoreModal" />
  <hour-worked-modal ref="hourWorkedModal" />
</template>
<script>
import GrossPayModal from "@/views/report/modals/GrossPayModal.vue";
import ProductionModal from "@/views/report/modals/ProductionModal.vue";
import WowAmountModal from "@/views/report/modals/WowAmountModal.vue";
import TipModal from "@/views/report/modals/TipModal.vue";
import PowerScoreModal from "@/views/report/modals/PowerScoreModal.vue";
import WowScoreModal from "@/views/report/modals/WowScoreModal.vue";
import HourWorkedModal from "@/views/report/modals/HourWorkedModal.vue";
export default {
  props: ['data', 'title', 'filters'],
  components: {GrossPayModal, ProductionModal, WowAmountModal, TipModal, PowerScoreModal,
    WowScoreModal, HourWorkedModal}
}
</script>