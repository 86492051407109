<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-8">
          <h1 class="m-0">Performance Report - Score</h1>
        </div><!-- /.col -->
        <div class="col-sm-4">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <div v-if="reports">
    <score-row-component title="Summary"
                   :filters="filters"
                   :data="reports.summary" />

    <score-row-component :title="'Week - ' + report.week_no + ' (' + report.week_start + ' - ' + report.week_end + ')'"
                   :key="'week_'+i"
                   :filters="filters"
                   v-for="(report, i) in reports.weekly"
                   :data="report.data" />
  </div>

  <report-modal-component></report-modal-component>
</template>

<script>
import ReportModalComponent from '@/components/ReportModalComponent';
import ScoreRowComponent from "@/views/report/components/ScoreRowComponent.vue";
export default {
  components: {ReportModalComponent, ScoreRowComponent},
  data() {
    return {
      reports: null,
      filters: {
        date: ''
      },
    }
  },
  mounted() {
    let start = window.moment().startOf('week');
    let end = window.moment();
    let self = this;

    window.$('#entry-date-range').daterangepicker(
      {
        autoUpdateInput: false,
        startDate: start,
        endDate: end,
        locale: {
          cancelLabel: 'Clear',
          format: 'MMM D, YYYY'
        },
        ranges: {
          'Today': [window.moment(), window.moment()],
          'This Week': [window.moment().startOf('week'), window.moment()],
          'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
          'This Quarter': [window.moment().quarter(window.moment().quarter()).startOf('quarter'), window.moment()],
          'Last Quarter': [window.moment().quarter(window.moment().quarter() - 1).startOf('quarter'), window.moment().quarter(window.moment().quarter() - 1).endOf('quarter')],
          'This Year': [window.moment().startOf('year'), window.moment()],
          'Last Year': [window.moment().subtract(1, 'years').startOf('year'), window.moment().subtract(1, 'years').endOf('year')],
          // 'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
          // 'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
          // 'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
        }
      },
      function (start, end) {
        self.changeDateRange(start, end);
      }
    )

    this.changeDateRange(start, end);
  },
  methods: {
    showDetails(type) {
      this.$store.commit('report/setTitle', type + ' (' + window.$('#entry-date-range span').html() + ')')
      this.$store.commit('report/setType', type);
      this.$store.commit('report/setJobs', []);

      let myModal = new window.bootstrap.Modal(document.getElementById('report-modal'));
      myModal.show();

      this.axios.get('reports/jobs', {
          params: {...this.filters, ...{type}}
        })
        .then((response) => {
          this.$store.commit('report/setJobs', response.data.data)
        })
    },
    loadData() {
      this.axios.get('reports', {
          params: this.filters
        })
        .then((response) => {
          this.reports = response.data.data;
        })
    },
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')

      this.loadData();
    }
  }
}
</script>